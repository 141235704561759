import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Is Your Smile Compromising Your Dating Life? | Smile Stylist Toronto" 
    description="Catch your dates attention in the confidence behind your smile. Porcelain Veneers can alter your look and the perception you give out in the dating world." 
    keywords="better smile, best smile, dentist blog, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Is Your Smile Compromising Your Online Dating Life?</h1>
              <h2>Why your teeth are #1 when it comes to the game of love…</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="blog hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Is Your Smile Compromising Your Online Dating Life?</h2>
            <h2>Why your teeth are #1 when it comes to the game of love…</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <img alt="Blog Banner" src={ require("../../images/desktop/_blog/pics/june2017-sm.jpg" )} className="img-thumbnail img-news hidden-md hidden-lg" />
            <p className="lead">When it comes to attracting and pursuing a potential mate–especially when dating online–your smile has a much bigger impact on your success than you might think.</p>
            <p>In fact, when it comes to judging a potential date, a Match.com survey found that 71% of women place a person’s smile as the most important feature, while this renders true for 58% percent of men. This means that a person’s smile is the NUMBER ONE deal maker (or breaker) for most people.</p>
            <p>Seriously… compared to other features, a smile gets a ton of consideration and can play a fundamental role in helping you find love. But why is that so? We’ll tell you why!</p>
            <h3>Here are 4 reasons why an attractive smile is the best thing you can wear on a first date (not to mention, lead with on your dating profile):</h3>
            <ol>
              <li>
                <strong>Your smile can make or break a  first impression</strong><br />
                What’s your first impression of a person with a brilliant smile? In another study, almost one-third of participants said that the first thing they notice about a person is their teeth, and most of them note that this is also the feature that they’ll remember most about them after meeting… not the clothes they’re wearing, not even their scent. And with that said, it’s been proven time and time again that a person with a straight, <a href="/teeth-whitening">white smile</a> is likely to be perceived as confident, happy and successful. Who doesn’t want to date someone with those qualities?
                <br /><br />
                Oh, not to mention… another study found that 73% of a group would be more likely to trust a person with a great smile compared to someone with a good career, car, or outfit. (And what’s love without trust)?
              </li>
              <li>
                <strong>Your smile is a strong indicator of your general health and hygiene habits</strong><br />
                A smile can tell a story about a person’s health and how they take care of themselves. When a person looks after their smile, it shows in its appearance. However, certain traits such as discoloured or yellowed teeth can indicate poor oral hygiene, when in fact it’s simply the person’s diet that has stained their smile. Alternatively, a crooked smile or incorrect bite can indicate improper development. Luckily, there are many ways to address these cosmetic problems, and our cosmetic dentist in Toronto knows best.
              </li>
              <li>
                <strong>A confident smile says, “I’m interested!”</strong><br />
                There’s nothing that says “I’m digging you” like a confident smile. A beautiful grin inspires good vibes on your date and can influence your date to smile, too (smiles are contagious, but a perfect smile can really work some magic)!  In fact, scientists have discovered that humans actually need to make a conscious effort not to smile when they’re witnessing another person doing so.
              </li>
              <li>
                <strong>A great smile makes you more attractive</strong><br />
                An unattractive or unsightly smile can have a hugely negative impact on a person’s overall appearance. A bad smile can turn off a potential lover or dating prospect in the snap of a finger. However, an attractive smile can do so much more for a person’s looks, and can really help you to stand out among the crowd of other eligible bachelors and bachelorettes.
              </li>
            </ol>
            <p>What have your teeth been doing for your online/dating life? If you feel like it’s time to perfect your smile, our Smile Stylist is your guy. Call us today to schedule a consultation</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img alt="Smile Makover" src={ require("../../images/desktop/_blog/pics/june2017-01.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.4s" /><br />
            <img alt="Better Smile Better Life" src={ require("../../images/desktop/_blog/pics/june2017-02.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage